import React, {useContext, useState} from "react";
import gql from "graphql-tag";
import {Query} from "react-apollo";
import {datoClient} from "../../apollo/client";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {categories, initials} from "../../templates/stylist";
import {Link} from "gatsby";
import MyAccountLayout from "../../components/myAccountLayout";
import {LikesContext} from "../../context/LikesContext";

const WishList = () => {
  const {likes} = useContext(LikesContext);
  const [selected, setSelected] = useState('stylist')

  const STYLIST_QUERY = gql`
      query Stylist($ids: [ItemId!]) {
          allStylists(filter: {id: {in: $ids}}) {
              id
              name
              avatar {
                  url
              }
              category
              city {
                  name
              }
          }
      }
  `

  const SHOPPING_PACKAGE_QUERY = gql`
      query ShoppingPackage($ids: [ItemId!]) {
          allShoppingpackages(filter: {id: {in: $ids}}) {
              id
              name
              slug
              city {
                  name
              }
              image {
                  id
                  url
              }
          }
      }
  `

  const SubMenu = ({label, target}) => {
    return <button
      onClick={() => setSelected(target)}
      className={'font-title font-bold text-2xl uppercase my-2 text-center tracking-wider mx-8 ' + (target === selected ? '' : 'text-gray-700')}
    >{label}</button>;
  }

  return <MyAccountLayout>
    <div className='flex flex-col justify-center items-center mx-2 lg:mx-32'>
      <h1
        className='font-title font-bold text-4xl uppercase py-2 text-center tracking-wider border-b w-full border-black'>Wishlist</h1>
      <div className="flex border-b w-full border-black justify-center">
        <SubMenu label='Stylists' target='stylist'/>
        <SubMenu label='Shopping packages' target='shoppingPackage'/>
      </div>
      {selected === 'stylist' && <Query
        client={datoClient}
        query={STYLIST_QUERY}
        variables={{ids: likes.stylist}}
      >{
        ({loading, error, data}) => {
          if (loading) {
            return <FontAwesomeIcon className='text-gray-800 mx-4' icon={faSpinner} spin={true} size="2x"/>
          }
          if (error) {
            return <div className="font-sans text-red-700">Something went wrong, please try again later</div>
          }
          return (
            <div className='flex flex-col w-full mb-32'>
              {data
                .allStylists
                .map(s =>
                  <div key={s.id} className="flex flex-wrap justify-between my-4">
                    <img src={s.avatar.url + '?auto=format&dpr=1&fit=crop&h=200&w=200'} alt=""/>
                    <div className="md:flex-1 mx-4 flex flex-col justify-start">
                      <h1
                        className='font-sans font-light text-3xl tracking-wide text-black text-start my-4'>{initials(s.name)}</h1>
                      {s.category && s.category.map(c => <div key={c}
                                                              className='font-sans font-light text-base my-1'>{categories[c]}</div>)}
                      {s.city && <div className='mb-2'>
                        <FontAwesomeIcon className='text-green-700' icon={faMapMarkerAlt} size="1x"/>
                        <span className='font-sans font-light mx-2'>{s.city.name}</span>
                      </div>
                      }
                    </div>
                    <div className="mx-4 my-2 flex flex-col flex-wrap justify-center">
                      <Link to={'/stylists/' + s.id + '/'}
                            className='mb-4 text-lg tracking-wide font-sans bg-yellow-700 hover:bg-black text-white font-bold py-2 px-8 focus:outline-none focus:shadow-outline uppercase'>View
                        details</Link>
                      <Link to={'/booking/?stylistId=' + s.id + '/'}
                            className='text-lg tracking-wide font-sans bg-yellow-700 hover:bg-black text-white font-bold py-2 px-8 focus:outline-none focus:shadow-outline uppercase'>Book
                        now</Link>

                    </div>
                  </div>)}
            </div>
          );
        }}
      </Query>}
      {selected === 'shoppingPackage' && <Query
        client={datoClient}
        query={SHOPPING_PACKAGE_QUERY}
        variables={{ids: likes.shoppingPackage}}
      >{
        ({loading, error, data}) => {
          if (loading) {
            return <FontAwesomeIcon className='text-gray-800 mx-4' icon={faSpinner} spin={true} size="2x"/>
          }
          if (error) {
            return <div className="font-sans text-red-700">Something went wrong, please try again later</div>
          }
          return (
            <div className='flex flex-col w-full mb-32'>
              {data
                .allShoppingpackages
                .map(s =>
                  <div key={s.id} className="flex justify-between my-4">
                    <img src={s.image.url + '?auto=format&dpr=1&fit=crop&h=200&w=200'} alt=""/>
                    <div className="flex-1 mx-4 flex flex-col justify-start">
                      <h1
                        className='font-sans font-light text-3xl tracking-wide text-black text-start my-4'>{s.name}</h1>
                      {s.city && <div className='mb-2'>
                        <FontAwesomeIcon className='text-green-700' icon={faMapMarkerAlt} size="1x"/>
                        <span className='font-sans font-light mx-2'>{s.city.name}</span>
                      </div>
                      }
                    </div>
                    <div className="mx-4 flex flex-col justify-start">
                      <Link to={'/shopping-packages/' + s.slug + '/'}
                            className='mb-4 text-lg tracking-wide font-sans bg-yellow-700 hover:bg-black text-white font-bold py-2 px-8 focus:outline-none focus:shadow-outline uppercase'>View
                        details</Link>
                      <Link to={'/booking/?shoppingPackageId=' + s.id + '/'}
                            className='text-lg tracking-wide font-sans bg-yellow-700 hover:bg-black text-white font-bold py-2 px-8 focus:outline-none focus:shadow-outline uppercase'>Book
                        now</Link>

                    </div>
                  </div>)}
            </div>
          );
        }}
      </Query>}

    </div>
  </MyAccountLayout>;
}

export default WishList